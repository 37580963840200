/* eslint-env browser */
import { DateTime } from 'luxon';

// timezone
const getClientTimeZone = () => {
  return DateTime.local().zoneName;
};

const createClientTimezoneCookie = () => {
  const oneYear = 60 * 60 * 24 * 365;
  document.cookie = `time_zone=${getClientTimeZone()};path=/;max-age=${oneYear};samesite=strict;`;
};

try {
  createClientTimezoneCookie();
} catch (error) {
  // swallow
}

// eslint-disable-next-line no-restricted-globals
if (location.pathname.includes('/organization_activities')) {
  $('document').ready(function onSearchFormReady() {
    const SEARCH_FORM = '#new_q';
    const VIEW = '#q_view';
    const TYPE = '#q_type';
    const DATE_FROM = '#q_from';
    const DATE_TO = '#q_to';
    const TITLE = '#q_title';
    const ARCHIVED = '#q_include_archived';
    const TIME_ZONE = '#q_time_zone';

    const CLEAR = '#searchReset';

    const REFRESH_FORM = '#refresh_form';
    const REFRESH_FORM_MESSAGE = '#refresh_form_message';
    const REFRESH_BUTTON = '#refresh_button';

    // selectors
    // ----------------------------------------------------------------------
    const $timezone = $(TIME_ZONE);
    const $clearButton = $(CLEAR);

    // operations
    // ----------------------------------------------------------------------
    const getTypeOptions = (type) => {
      if (type === 'engagement_type') {
        return {
          submissions_view: 'Submissions',
          engagements_view: 'Engagements',
        };
      }
      if (type === 'idea_boards_type') {
        return {
          idea_boards_view: 'Public Boards',
          ideas_view: 'Ideas',
        };
      }
      // eslint-disable-next-line no-console
      console.error(new Error(`Cannot find options for type: ${type}`));
      return false;
    };

    const replaceTypeOptions = (type) => {
      const options = getTypeOptions(type);
      if (options) {
        const $el = $(VIEW);
        $el.empty();
        $.each(options, function htmlReplaceOptions(key, value) {
          $el.append($('<option>').attr('value', key).text(value));
        });
      }
    };

    const replaceArchivedLabelTag = (type) => {
      const types = {
        engagement_type: 'Include Archived Engagements',
        idea_boards_type: 'Include Archived Public Boards',
      };
      $('label[for="q_include_archived"] .control-label').text(types[type]);
    };

    const clearSearchForms = () => {
      const defaultType = 'engagement_type';
      const defaultView = 'submissions_view';
      document.querySelector(TYPE).value = defaultType;
      document.querySelector(VIEW).value = defaultView;
      document.querySelector(TITLE).value = null;
      document.querySelector(ARCHIVED).checked = false;

      // reset to/from fields to default
      document.querySelector(DATE_FROM).value = gon.searchForm.defaultFrom;
      document.querySelector(DATE_TO).value = gon.searchForm.defaultTo;

      replaceTypeOptions(defaultType);
      replaceArchivedLabelTag(defaultType);
    };

    // Watchers
    // ----------------------------------------------------------------------
    $(TYPE).change(function onChangeType() {
      const val = $(this).val();
      replaceTypeOptions(val);
      replaceArchivedLabelTag(val);
    });

    // Clear each form when the clear buttons are clicked
    $clearButton.click(function onSearchFormClearButtonClick(e) {
      e.preventDefault();
      clearSearchForms();
    });

    const $refreshForm = $(REFRESH_FORM);
    const $refreshFormMessage = $(REFRESH_FORM_MESSAGE);
    const $refreshButton = $(REFRESH_BUTTON);

    $refreshForm.on('ajax:before', function onRefreshSuccess() {
      $refreshButton.prop('disabled', true);
    });

    $refreshForm.on('ajax:success', function onRefreshSuccess() {
      window.location.reload();
    });

    $refreshForm.on('ajax:error', function onRefreshFormError() {
      $refreshFormMessage
        .attr('role', 'alert')
        .text('Refresh was unsuccessful, sorry about that! Please try refreshing your page.');
      $refreshButton.removeClass('is-loading');
    });

    $refreshButton.click(function onRefreshClick() {
      $refreshButton.addClass('is-loading');
      $refreshFormMessage.attr('role', 'alert').text('Please wait. This may take a few minutes...');
    });

    // Initialize
    // ----------------------------------------------------------------------
    // capture client timezone
    $timezone.val(getClientTimeZone());

    if (document.querySelector(SEARCH_FORM)) {
      replaceArchivedLabelTag(gon.searchForm.type);
    }
  });
}
